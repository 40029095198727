import React from 'react'
import { StickyProvider } from 'contexts/app/app.provider'
import SEO from 'components/seo'
import Layout from 'components/layout'

// Load other package css file
import 'react-multi-carousel/lib/styles.css'
import 'react-modal-video/css/modal-video.min.css'
import 'rc-drawer/assets/index.css'
// Custom css style
import 'assets/css/style.css'
// Plugins css style
import 'assets/plugins/bootstrap/bootstrap.min.css'
import 'assets/plugins/themify-icons/themify-icons.css'
import 'assets/plugins/slick/slick.css'
import 'assets/plugins/slick/slick-theme.css'
import 'assets/plugins/fancybox/jquery.fancybox.min.css'
import 'assets/plugins/aos/aos.css'

export default function PrivacyPolicyPage() {
    return (
        <StickyProvider>
            <Layout>
                <SEO title="Politique de confidentialité" />
                <section class="section page-title">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-8 m-auto">
                                <h1>Demande de suppression d'un compte NiWapi</h1>
                                <p>(<em>Dernière mise à jour le 2 août 2024 à 23h45'</em>)</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="privacy section pt-0">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="block">
                                    <p>
                                    Veuillez envoyer un <b>mail</b> à notre adresse avec l'adresse email utilisée 
                                    sur l'application. Notez que toutes vos données seront supprimées, et 
                                    cela prendra effet dans un délai de 30 jours.
                                    </p>
                                    <p>
                                    Adresse email : <b>dev@ni-wapi.com</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </StickyProvider>
    );
}
